<template>
  <div class="personal">
    <Card padding-not-remove size="small" class="personal-card">
      <template v-slot:header>
        <h2>Profile</h2>
      </template>
      <template v-slot:body>
        <div class="user-avatar" :class="[avatarEdit? 'edit': '']">
          <template v-if="avatarEdit">
            <AvatarEditor v-model="newAvatar" id="account-avatar-edit"/>
          </template>
          <template v-else>
            <Avatar :size="56" id="account-user-avatar" class="avatar" :src="avatarThumbnail" :full-name="fullName"/>
            <Button text="Upload profile picture" class="button-avatar" style-modifiers="text" @click="avatarEdit = true"></Button>
          </template>
        </div>
        <div class="user-data">
          <TextInputNew size="s" id="first-name-account" name="first-name" label="First name" v-model="firstName"/>
          <TextInputNew size="s" id="last-name-account" name="last-name" label="Last name" v-model="lastName"/>
          <TextInputNew size="s" id="email-account" name="email" label="Email" v-model="email"/>
          <TextInputNew size="s" id="university-account" name="university" label="University name" v-model="university"/>
        </div>
      </template>
      <template v-slot:footer>
        <ButtonNew mode="green" size="l" text="Save change" :loading="loadingUpdate" @click="updateUserInfo"/>
      </template>
    </Card>
    <Card padding-not-remove size="small" class="personal-card">
      <template v-slot:header>
        <h2>Password Change</h2>
      </template>
      <template v-slot:body>
        <ObPrompt
            icon-name="lock-icon"
            content="To update your password, we will send a special link to your email address. Check the spam section if no email arrives. After clicking on the button, your session will be ended."
            class="password-update-text"
        />
      </template>
      <template v-slot:footer>
        <ButtonNew mode="green" size="l" text="Update password" :loading="loadingUpdatePassword" @click="updatePassword" />
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/UI-Kit/Card";
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import TextInputNew from "@/components/UI-Kit/TextInputNew";
import { ref, reactive, inject, watch, computed, toRefs } from "vue";
import Avatar from "@/components/UI-Kit/Avatar";
import Button from "@/components/UI-Kit/Button";
import AvatarEditor from "@/components/UI-Kit/AvatarEditor";
import {logout} from "@/setups/authSetup";
import {updateUserInfoRequestApi, forgotPasswordRequestApi} from "@/api/auth";
import {userInitialization} from "@/setups/initAppSetup";
import ObPrompt from "@/components/OnboardingQuestionPrompt";
import Modal from "@/components/UI-Kit/Modal";

const userDataSetup = function() {
  const userData = inject('user')
  const getUserData = inject('getUserData')
  const avatarEdit = ref(false)
  const newAvatar = ref({})
  const loadingUpdate = ref(false)
  const loadingUpdatePassword = ref(false)

  const updateUserInfo = async function() {
    loadingUpdate.value = true
    await updateUserInfoRequestApi(newAvatar.value, {...userData})
    await getUserData()
    loadingUpdate.value = false
  }

  const updatePassword = async function () {
    loadingUpdatePassword.value = true
    logout()
    await forgotPasswordRequestApi(userData.email)
    loadingUpdatePassword.value = false
  }

  return {
    avatarEdit, newAvatar,
    loadingUpdate, updateUserInfo,
    loadingUpdatePassword, updatePassword,
    ...toRefs(userData)
  }
}

export default {
  name: "AccountPersonal",
  components: {Modal, ObPrompt, AvatarEditor, Button, Avatar, TextInputNew, ButtonNew, Card},
  props: {
    workspaceId: String
  },
  setup: userDataSetup
}
</script>

<style scoped lang="scss">
.personal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
  align-items: flex-start;
  margin-top: 24px;

  &-card {
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  }

  .user-avatar {
    padding: 0 0 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: flex-start;

    &.edit {
      flex-direction: column;
    }

    .avatar {
      width: 56px;
    }

  }
}

.button-avatar {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  flex-shrink: 0;
  /* identical to box height, or 129% */
  margin-left: 16px;
  width: 152px;
  color: #0CAC60;
}

.password-update-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */


  color: #80869D;
}

</style>
