<template>
  <div class="account">
    <h1>My Account</h1>
    <Tabs class="tab" id="account-mode-tabs" v-model="modeAccount" :modes="['Workspaces', 'Personal'/*, 'Billing'*/]"/>
    <router-view/>
  </div>
</template>

<script>
import { inject, ref, watch } from "vue";
import { myWorkspaceRequestApi } from "@/api/workspace";
import Avatar from "@/components/UI-Kit/Avatar";
import Card from "@/components/UI-Kit/Card";
import TextInput from "@/components/UI-Kit/TextInput";
import Button from "@/components/UI-Kit/Button";
import WorkspaceCard from "@/components/Account/WorkspaceCard";
import IconNew from "@/components/UI-Kit/IconNew";
import Tabs from "@/components/UI-Kit/Tabs";
import AccountWorkspaces from "@/components/Account/AccountWorkspaces";
import AccountPersonal from "@/components/Account/AccountPersonal";
import { useRouter, useRoute } from "vue-router"
import { logout } from "@/setups/authSetup";


const setup = function() {
  const route = useRoute()
  const modeAccount = ref(route.name.slice(7, route.name.length))
  const router = useRouter()


  watch(modeAccount, (newValue) => {
    newValue = newValue.split(" ")[0]
    router.push({
      name: 'Account' + newValue
    })
  })

  const logoutClick = function() {
    logout();
    router.push({
      name: "Auth"
    });
  };

  return {
    modeAccount,
    logoutClick
  };
};

export default {
  name: "Account",
  props: {
    workspaceId: String
  },
  components: {AccountPersonal, AccountWorkspaces, IconNew, WorkspaceCard, Button, Avatar, Card, TextInput, Tabs },
  setup
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/color-scheme.scss";


.account {
  min-height: 100vh;
  height: 100%;
  background-color: $global-secondary-background;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
  .logo {
    width: 131px;
    height: 23px;
    margin: 0 auto 24px auto;
  }

  .tab {
    margin-top: 8px;
  }
;
}

.pxp__profile {

}


.logout {
  position: absolute;
  top: 40px;
  right: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 8px;
  &-icon {
    width: 16px;
    height: 16px;
  }
  p {
    color: $pxp-gray-color-darken;
  }
  cursor: pointer;
}
</style>
