<template>
  <div class="ob__prompt">
    <IconNew :name="iconName" class="prompt__icon" :style="{width: iconSize + 'px', height: iconSize + 'px'}"></IconNew>
    <div class="prompt__text">
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
import IconNew from "@/components/UI-Kit/IconNew";

export default {
  name: "OnboardingQuestionPrompt",
  components: { IconNew },
  props: {
    content: {
      type: String,
      required: true
    },
    iconName: {
      type: String,
      required: false,
      default: 'idea-icon'
    },
    iconSize: {
      type: Number,
      required: false,
      default: 48
    }
  }
};
</script>

<style scoped>
.ob__prompt {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}
.prompt__text {
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  line-height: 125%;
}
.prompt__icon {
  margin-top: 0;
  flex-shrink: 0;
}
</style>
